body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background-color: #f4f7fa;
  --text-color: #333;
  --secondary-text-color: #777;
  --card-background-color: #fff;
  --card-background-image: linear-gradient(135deg, #6e7dff, #b5e1fc);
  --line-color: #ccc;
  --profile-border-color: #007bff;
  --quick-links-bg: #f9f9f9; /* Background for Quick Links */
  --link-color: #007bff; /* Color for link text */
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --text-color: #fff;
    --secondary-text-color: #bbb;
    --card-background-color: #333;
    --card-background-image: none;
    --line-color: #444;
    --profile-border-color: #fff;
    --quick-links-bg: #2c2c2c;
  }
} /* Dark mode background for Quick Links
--link-color: #fff; /* Dark mode link text color */

@tailwind base;
@tailwind components;
@tailwind utilities;
